import { nameSpace } from './custom-events';
import type AppBase from './';
import type { PageBase } from '@components/page-base';
import type Template from 'src/app/Template';
import { ShowMessageArguments, ShowMessageResolved } from './types';
// in some cases, there is no element to dispatch certain events on.
// then, it is necessary to invoke methods of AppShell directly.
// to prevent import cycles, we need to use this helper instead of importing AppShell
export const getAppElement: () => AppBase = () => {
	const [appShellElement] = document.getElementsByTagName(
		nameSpace.value,
	) as HTMLCollectionOf<AppBase>;
	return appShellElement;
};

export const getCurrentView: () => PageBase | Template | undefined = () =>
	getAppElement()?.view;

// let's try to avoid using this.
// you could always render a <yd-dialog> element where you need it and add respective listeners to the the element
export const showAppDialog = (
	args: ShowMessageArguments,
): Promise<ShowMessageResolved> => {
	const appElement = getAppElement();
	return new Promise((resolve: (arg: ShowMessageResolved) => void) => {
		if (!appElement) {
			resolve({ isDismissed: true });
			return;
		}
		appElement.dialog = {
			...args,
			resolve,
		};
	});
};
