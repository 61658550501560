/* eslint-disable no-undefined */
// https://openapi-ts.pages.dev/openapi-fetch/middleware-auth
import type { Middleware, MiddlewareOnResponse } from 'openapi-fetch';

export const addAuthenticationErrorHandling = (
	onAuthError: (options: Parameters<MiddlewareOnResponse>) => void,
): Middleware => ({
	async onResponse(options) {
		const { response, schemaPath } = options;
		if (
			schemaPath === '/logout' ||
			!response.ok ||
			!response.headers.get('content-type')?.includes('application/json')
		) {
			return undefined;
		}
		const { status } = response;
		const { error } = await response.clone().json(); // https://openapi-ts.pages.dev/openapi-fetch/middleware-auth#handling-statefulness
		if (
			error === 'auth' || // APIv1
			status === 401 || // APIv2
			error?.code === 'AUTHENTICATION_ERROR' // APIv2
		) {
			onAuthError?.(options);
		}

		return undefined;
	},
});
