import type { YmdRoute } from '@router/types';

export default <Record<string, YmdRoute>>{
	'/notfound': {
		as: 'notfound',
		hasHeader: true,
		hasAsideNavigation: true,
		hasPageTracking: true,
		module: () => import('./'),
	},
};
