import type { ArbitraryArrowFunction, YmdRoute } from '@router/types';

export default <Record<string, YmdRoute>>{
	'/verify/:token': {
		as: 'verify',
		module: () => import('./'),
		uses: (match, ymdRoute, router) =>
			(ymdRoute.routeDefaultExport as ArbitraryArrowFunction)(
				match.data?.token,
				router.navigate,
			),
	},
};
