import Navigo from 'navigo';
import type { YmdRoute, Match } from './types';

export default class YmmdRouter extends Navigo {
	ymdRoutes: Record<string, YmdRoute> = {};

	defaultRouteConfig = {
		hasHeader: true,
		hasAsideNavigation: true,
		isAuthRequired: true,
		isAllowedWithoutVerifiedEmail: false,
		hasPageTracking: false,
	};

	listener: (
		route: YmdRoute,
		params: Match['data'],
		query: Match['params'],
	) => void = () => {
		throw new Error('you need to pass a listener when starting routing');
	};

	subscribe(listener: YmmdRouter['listener'] = () => {}) {
		this.listener = listener;
		this.on(this.ymdRoutes).resolve();
	}

	registerRoutes(newRoutes: YmmdRouter['ymdRoutes']) {
		this.ymdRoutes = {
			...this.ymdRoutes,
			...Object.fromEntries(
				Object.entries(newRoutes).map(([path, conf]) => {
					let ymdRoute = {
						...this.defaultRouteConfig,
						...conf,
					};
					return [
						path,
						{
							...ymdRoute,
							hooks: {
								before: async (done, match) => {
									// preload module to cancel routing if imported module errors
									if (ymdRoute.module) {
										const { default: routeDefaultExport } =
											await ymdRoute.module();
										if (!routeDefaultExport) {
											done(false);
											return;
										}
										ymdRoute = {
											...ymdRoute,
											routeDefaultExport,
										};
									}

									if (ymdRoute.hooks?.before) {
										ymdRoute.hooks.before(done, match, ymdRoute, this);
									} else {
										done();
									}
								},
								after: (match: Match) => {
									ymdRoute.hooks?.after?.(match, ymdRoute, this);
								},
								already: (match: Match) => {
									ymdRoute.hooks?.already?.(match, ymdRoute, this);
								},
								leave: (done, match: Match) => {
									if (ymdRoute.hooks?.leave) {
										ymdRoute.hooks.leave(done, match, ymdRoute, this);
									} else {
										done();
									}
								},
							},
							uses: (match: Match) => {
								if (ymdRoute.uses) {
									ymdRoute.uses?.(match, ymdRoute, this);
								} else {
									this.listener(ymdRoute, match.data, match.params);
								}
							},
						},
					];
				}),
			),
		};
	}

	updateURLonly = (path: string): void => {
		this.navigate(path, {
			callHandler: false,
			callHooks: false,
		});
	};
}
