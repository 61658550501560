import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/score': {
		...talentRouteDefaultConfig,
		as: 'score',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
