import { talentRouteDefaultConfig, type TalentRoute } from '../../router';
import { Model } from '@app/Model.js';
import { User } from '@app/User';
import { Router } from '@app/router';

const isSalesToolAdmin = async (): Promise<boolean> => {
	const rights = await Model.data.salessuccessreportrights();
	User.updateUser({ isSalesToolAdmin: rights.admin });
	if (User.isOnboarded && User.user?.isSalesToolAdmin) {
		return rights.admin;
	}
	if (User.isOnboarded) {
		Router.navigate(`/${window.location.search}`);
		return false;
	}

	return rights.admin;
};

export default <Record<string, TalentRoute>>{
	'/adminsalestool': {
		...talentRouteDefaultConfig,
		as: 'adminsalestool',
		hasAsideNavigation: false,
		hasAsideSalesToolNavigation: true,
		module: () => import('./'),
		hooks: {
			...talentRouteDefaultConfig.hooks,
			before: async (done, ...args) => {
				const ok = await isSalesToolAdmin();
				if (!ok) {
					done(ok);
					return;
				}
				talentRouteDefaultConfig.hooks?.before?.(done, ...args);
			},
		},
	},
};
