/* eslint-disable camelcase */
import { User } from '@app/User';
import { globals } from '@globals';
import { readCookieByName } from '@utils';
import { Log } from '@modules/Log';
import { getAppElement } from '@components/app-base/helper';

interface ConsentLevels {
	functionality?: boolean;
	'strictly-necessary'?: boolean;
	tracking?: boolean;
	targeting?: boolean;
}

interface EventActions {
	user: 'userInteraction';
	ui: 'uiTriggered';
	page: 'trackPageView';
}

type ValueOf<T> = T[keyof T];

const trackingCategories = {
	talent: {
		ob: [
			'join',
			'account',
			'onboarding',
			'mobiledocupload',
			'webcamsession',
			'welcome',
		],
		messaging: ['mailbox'],
		'Talent Main Navigation': ['tal'],
	},
	manage: {
		progress: ['cuaccount', 'balance', 'addservice', 'findtalents'],
		'Create Quality Check': ['qualityadd'],
		'Manage Quality Assurance': ['quality', 'qualityview', 'qualitycheck'],
		'Manage Monitoring Dashboard': ['monitor'],
		// Note: Global categories have specific implementation
		'Manage Platform Header': ['header'],
		'Manage Main Navigation': ['mg'],
	},
	onboarding: {},
	backoffice: {},
};

const findCategory = (val: string) => {
	const guiTrackingCategories = trackingCategories[globals.gui];

	const currentCategoryIndex = Object.values(guiTrackingCategories).findIndex(
		(viewNames) => viewNames.some((viewName) => val.startsWith(viewName)),
	);

	return Object.keys(guiTrackingCategories)[currentCategoryIndex];
};

const getTrackingCategory = (source: string) => {
	let category = findCategory(source);

	if (!category) {
		const { as: currentViewname = '' } = getAppElement()?.route || {};
		category = findCategory(currentViewname);
	}

	return category;
};

const canTrack = (): boolean => {
	const levelCookie = readCookieByName('cookie_consent_level') || '';
	if (
		levelCookie === '' ||
		!window._paq ||
		!window._mtm ||
		!globals.isProduction // only track on prod system
	) {
		return false;
	}
	try {
		return (
			(JSON.parse(decodeURIComponent(levelCookie)) as ConsentLevels).tracking ||
			false
		);
	} catch (error: unknown) {
		Log.error(error as Error);
		return false;
	}
};

const userId = () => User.user?.id || null;

const isAlreadyTracked = ({
	dataLayer,
	eventName,
}: {
	dataLayer: Window['_mtm'];
	eventName: string;
}): boolean =>
	dataLayer.some(
		(mtmEvent) =>
			'event_data' in mtmEvent && mtmEvent.event_data?.name === eventName,
	);

export const matomoTracker = {
	init(src: string) {
		window._mtm = window._mtm || [];
		const { _mtm } = window;
		_mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
		const d = document;
		const g = d.createElement('script');
		const [s] = d.getElementsByTagName('script');
		g.async = true;
		g.src = src;
		s.parentNode!.insertBefore(g, s);
	},

	eventTrackForeignUser(userid = '', ...trackingParams: Array<string>) {
		if (!canTrack() || !userid) return;
		window._paq.push(['setCustomUrl', window.location.pathname]);
		window._paq.push(['setUserId', userid]);
		window._paq.push(['trackEvent', ...trackingParams]);
	},

	eventTrack(categroy?: string, action?: ValueOf<EventActions>, name?: string) {
		if (!canTrack()) return;
		window._paq.push(['setCustomUrl', window.location.pathname]);
		window._paq.push(['setUserId', userId()]);
		window._paq.push(['trackEvent', categroy, action, name]);
	},

	track({
		trigger = 'user',
		name,
		once = false,
		options = {},
	}: {
		trigger?: keyof EventActions;
		name: string;
		once?: boolean;
		options?: object;
	}) {
		const { _mtm } = window;
		if (
			!canTrack() ||
			(once && isAlreadyTracked({ dataLayer: _mtm, eventName: name }))
		) {
			return;
		}

		if (!_mtm.some((mtmEvent) => 'uid' in mtmEvent)) {
			_mtm.push({ uid: userId() });
		}

		const eventValues: EventActions = {
			user: 'userInteraction',
			ui: 'uiTriggered',
			page: 'trackPageView',
		};

		const [source] = name.split('.');

		_mtm.push({
			event: 'event-to-tag',
			event_data: {
				name,
				action: eventValues[trigger],
				category: getTrackingCategory(source),
				options: { ...options, trackOnce: once },
			},
		});
	},

	pageTrack() {
		if (!canTrack()) return;
		setTimeout(() => {
			if (window._svc && User.hasSession) {
				window._sva?.setVisitorTraits({
					user_id: 'yoummday', // this should not be a real userId!
				});
			}
			const dimensions = {
				dimension2: User.company?.productPlan,
				dimension3: User.company?.company,
			};
			window._paq.push(['setCustomUrl', window.location.pathname]);
			window._paq.push(['setUserId', userId()]);

			window._paq.push(['trackPageView', document.title, dimensions]);

			window._mtm.push({
				event: 'trackPageView',
				customUrl: window.location.pathname,
			});
		}, 100);
	},
	conversionTrack(convEvent = '') {
		if (!canTrack() || !convEvent) return;
		window._mtm.push({
			event: 'event-to-advertiser',
			'event-to-advertiser': convEvent,
		});
	},
};

type MtmPayloadDefault = Array<string | null>;
type MtmPayloadAdvanced = {
	event_data?: {
		name?: string;
		action: 'userInteraction' | 'uiTriggered' | 'trackPageView';
		category: string;
		options: { trackOnce: boolean } & object;
	};
	uid?: string | null;
	'mtm.startTime'?: string | number;
	event?: string;
	customUrl?: string;
	'event-to-advertiser'?: string;
};
declare global {
	interface Window {
		_mtm: Array<MtmPayloadDefault | MtmPayloadAdvanced>;
		_paq: Array<(string | null)[] | object>;
		_svc: object;
		_sva: {
			setVisitorTraits: (arg0: { user_id: string }) => void;
		};
	}
}
