import { User } from '@app/User.ts';
import { html } from 'lit';
import { getSipDownloads } from '../subtemplates/misc.js';
import { showAppDialog } from '@components/app-base/helper.ts';

const getDialog = () => {
	const { user } = User;
	return html`
		<p><i>${window.T.notice.softphone_config}</i></p>
		${getSipDownloads()}
		<yd-table class="mt-2">
			<yd-table-thead>
				<yd-table-header-cell>
					${window.T.label.sip_credentials}
				</yd-table-header-cell>
				<yd-table-header-cell></yd-table-header-cell>
			</yd-table-thead>
			<yd-table-tbody>
				<yd-table-row>
					<yd-table-cell>${window.T.label.sip_credentials_user}</yd-table-cell>
					<yd-table-cell>${user.softphoneUser}</yd-table-cell>
				</yd-table-row>
				<yd-table-row>
					<yd-table-cell>
						${window.T.label.sip_credentials_password}
					</yd-table-cell>
					<yd-table-cell>${user.softphonePassword}</yd-table-cell>
				</yd-table-row>
				<yd-table-row>
					<yd-table-cell>${window.T.label.sip_credentials_host}</yd-table-cell>
					<yd-table-cell>sip.yoummday.com</yd-table-cell>
				</yd-table-row>
				<yd-table-row>
					<yd-table-cell>
						${window.T.label.sip_credentials_protocol}
					</yd-table-cell>
					<yd-table-cell>TLS</yd-table-cell>
				</yd-table-row>
			</yd-table-tbody>
		</yd-table>
	`;
};

export const openSoftphoneDialog = (title, user) => {
	showAppDialog({
		html: getDialog(title, user),
		noDismissButton: true,
		noConfirmButton: true,
		titleText: window.T.headline.softphone_config,
	});
};
