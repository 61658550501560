class YmdStorage {
	private partition: string;
	private storageType: Storage;
	public Log?: {
		log?: (...args: unknown[]) => void;
		error: (error: Error) => void;
	} | null = console;
	constructor(partition = '', type: 'local' | 'session' = 'session') {
		this.partition = `YMD${partition ? `.${partition}` : ''}`;
		this.storageType = window[`${type}Storage`];
		if (
			!this.storageType ||
			this.storageType.toString() !== '[object Storage]'
		) {
			this.Log?.error(new Error('invalid storage type'));
		}
	}

	get(key: string) {
		const stored = this.storageType.getItem(`${this.partition}.${key}`);
		if (!stored) return null;
		try {
			return JSON.parse(stored);
		} catch (err: Error | unknown) {
			this.Log?.error(err as Error);
			return stored;
		}
	}

	set(key: string, value: unknown) {
		try {
			this.storageType.setItem(
				`${this.partition}.${key}`,
				JSON.stringify(value),
			);
		} catch (err: Error | unknown) {
			// storing failed, most certainly the object is too big to store
			this.Log?.error(err as Error);
		}
		return value;
	}

	has(key: string) {
		return this.keys().includes(key);
	}

	update(key: string, updateObj: Record<string, unknown> = {}) {
		return this.set(key, {
			...(this.get(key) || {}),
			...updateObj,
		});
	}

	push(key: string, arrayEl: unknown) {
		const arr = this.get(key) || [];
		arr.push(arrayEl);
		return this.set(key, arr);
	}

	keys(): string[] {
		return Object.keys(this.storageType)
			.filter((key) => key.startsWith(this.partition))
			.map((key) => key.substring(this.partition.length + 1));
	}

	remove(key: string): void {
		this.storageType.removeItem(`${this.partition}.${key}`);
	}

	removeAll(): void {
		this.keys().forEach((key) => {
			this.remove(key);
		});
	}
}

export { YmdStorage as Storage };
