import { css, html, LitElement, unsafeCSS, type TemplateResult } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import style from './style.scss?inline';
import { nameSpace, type createNavigateEvent } from './custom-events';
import platformBackground from './assets/platform-background.svg?url';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@frontend/yd-components/dist/components/iconify-icon.js';
import '@frontend/yd-components/dist/components/yd-dialog/index.js';

import type {
	ShowMessageArguments,
	ShowMessageEventDetails,
	ShowMessageResolved,
	ShowToastMessageEventDetails,
} from './types';
// eslint-disable-next-line no-duplicate-imports
import type SlAlert from '@shoelace-style/shoelace/dist/components/alert/alert.js';
import type YdDialog from '@frontend/yd-components/dist/components/yd-dialog/yd-dialog';

export default class AppBase extends LitElement {
	static styles = [
		style,
		css`
			:host {
				background-image: ${unsafeCSS(`url(${platformBackground})`)};
			}
		`,
	];
	nameSpace = this.localName as `${string}-${string}`;

	@property({ type: Boolean }) isLoading: boolean = false;

	connectedCallback() {
		nameSpace.value = this.nameSpace;
		super.connectedCallback();

		this.addEventListener(
			`${this.nameSpace}-navigate` as 'navigate',
			this.onNavigate,
		);
		this.addEventListener(`${this.nameSpace}-rerender` as 'rerender', () => {
			this.requestUpdate();
		});
		this.addEventListener(
			`${this.nameSpace}-showMessage` as 'showMessage',
			this.onShowMessage,
		);

		this.addEventListener(
			`${this.nameSpace}-showToast` as 'showToast',
			this.onShowToast,
		);
		this.addEventListener(
			`${this.nameSpace}-destroyToast` as 'destroyToast',
			this.onDestroyToast,
		);
		this.addEventListener(
			`${this.nameSpace}-logout` as 'logout',
			this.onLogout,
		);
	}

	onNavigate(e: ReturnType<typeof createNavigateEvent>) {
		throw new Error(
			`A navigate event (${e.detail}) was catched, but there is no onNavigate handler declared.`,
		);
	}

	onLogout({ detail: onPurpose }: CustomEvent<boolean>) {
		throw new Error(
			`A logout event (onPurpose: ${onPurpose}) was catched, but there is no onLogout handler declared.`,
		);
	}

	onShowToast({
		detail: { element, variant, onDismissed },
	}: CustomEvent<ShowToastMessageEventDetails>) {
		const alert = document.createElement('sl-alert');
		alert.variant = variant || 'neutral';
		alert.closable = true;
		alert.append(element);

		this.append(alert);
		alert.addEventListener('sl-after-hide', () => {
			onDismissed();
			alert.remove();
		});
		return alert.toast();
	}
	onDestroyToast() {
		const alert = document.body.querySelector('sl-alert');
		alert?.hide();
	}

	@state() snackBarMessage?: {
		msg: string | TemplateResult;
		variant: SlAlert['variant'];
		icon: string;
	} | null;
	@query('.snack-bar') snackBarElement!: SlAlert;
	showSnackBar({ str, variant = 'success' }: ShowMessageEventDetails) {
		if (!str) return;
		const icons: { [key: string]: string } = {
			danger: 'material-symbols:report-outline-rounded',
			primary: 'material-symbols:info-outline-rounded',
			success: 'material-symbols:check-circle-outline-rounded',
			warning: 'material-symbols:warning-outline-rounded',
		};

		this.snackBarMessage = {
			variant,
			icon: icons[variant],
			msg: str,
		};
		this.snackBarElement.show();
	}
	renderSnackBar(): TemplateResult | string {
		const { variant = 'success', msg, icon = '' } = this.snackBarMessage || {};
		return html`
			<sl-alert
				class="snack-bar"
				.variant=${variant}
				.closable=${true}
				.duration=${3000}
				@sl-after-hide=${() => (this.snackBarMessage = null)}
			>
				<iconify-icon
					icon=${icon}
					width="22"
					slot="icon"
				></iconify-icon>
				${msg}
			</sl-alert>
		`;
	}

	@property({ type: Object }) dialog!: ShowMessageArguments;
	showDialog(args: ShowMessageArguments): Promise<ShowMessageResolved> {
		return new Promise((resolve: (arg: ShowMessageResolved) => void) => {
			this.dialog = {
				...args,
				resolve,
			};
		});
	}

	onShowMessage({ detail }: { detail: ShowMessageEventDetails }) {
		if (detail.variant === 'danger') {
			this.dialog = {
				html: detail.str,
				noDismissButton: true,
				variant: detail.variant,
				titleText: window.T.term.error,
			};
		} else {
			this.showSnackBar(detail);
		}
	}

	renderDialog() {
		return html`
			<yd-dialog
				@opened=${(e: CustomEvent) => {
					const dialogElement = e.currentTarget as YdDialog;
					this.dialog?.didOpen?.(dialogElement);
				}}
				@dismissed=${() => {
					this.dialog?.resolve?.({ isDismissed: true });
					this.dialog = null;
				}}
				@confirmed=${(e: CustomEvent) => {
					const dialogElement = e.currentTarget as YdDialog;
					let confirmValue = e.detail?.value || {};
					if (this.dialog?.preConfirm) {
						confirmValue = this.dialog.preConfirm(dialogElement);
						if (confirmValue === false) {
							return;
						}
					}
					this.dialog?.resolve?.({
						isDismissed: false,
						value: confirmValue,
					});
					this.dialog = null;
				}}
				.scrollable=${!!this.dialog?.scrollable}
				.variant=${this.dialog?.variant}
				.noDismissButton=${this.dialog?.noDismissButton}
				.noConfirmButton=${this.dialog?.noConfirmButton}
				.noEscape=${this.dialog?.noEscape}
				.size=${this.dialog?.size}
				.type=${this.dialog?.type}
				confirmButtonText=${this.dialog?.confirmButtonText || window.T.cta.ok}
				dismissButtonText=${this.dialog?.dismissButtonText ||
				window.T.cta.cancel}
			>
				${when(
					this.dialog?.titleText,
					() => html`
						<div slot="header">${this.dialog?.titleText}</div>
					`,
				)}
				${this.dialog?.html}
			</yd-dialog>
		`;
	}
}
