import { User } from '@app/User.ts';
import { Model } from '@app/Model.js';
import { html } from 'lit';
import { clock } from '@utils/index.ts';
import { matomoTracker } from '@modules/matomoTracker.ts';
import { openAppAuthDialog } from './modal/appauth.js';
import { openSoftphoneDialog } from './modal/softphone.js';
import { when } from 'lit/directives/when.js';
import './components/support-panel/index.ts';
import '@components/web-phone/index.ts';
import '@components/mail-indicator/index.ts';
import {
	createAlertMessageEvent,
	createRerenderEvent,
	createLogoutEvent,
} from '@components/app-base/custom-events.ts';
import { i18n } from '@i18n/index.ts';

export const header = () => {
	const supportPanel = document.createElement('support-panel');
	const setTimezone = async function (e) {
		await Model.data.account({ timezone: e.currentTarget.value });
		window.location.reload();
	};
	const setOnline = async (e) => {
		e.preventDefault();
		const element = e.currentTarget;
		await Model.data.account({
			connectionPhone: Number(!User.user.connectionPhone),
		});
		element.dispatchEvent(createRerenderEvent());
	};
	const toggleSupportPanel = () => {
		supportPanel.isOpen = !supportPanel.isOpen;
		matomoTracker.eventTrack(
			'Talent Platform Help',
			'userInteraction',
			`support.panel.${supportPanel.isOpen ? 'open' : 'close'}`,
		);
	};

	const phoneSection = () => {
		const { connectionType = 'webphone', connectionPhone = 0 } =
			User.user || {};

		const switchPhoneType = async function (e) {
			const self = e.currentTarget;
			const { user } = await Model.data.account({ connectionType: self.value });
			self.dispatchEvent(createRerenderEvent());
			if (user.connectionType === 'softphone') {
				self.dispatchEvent(
					createAlertMessageEvent(window.T.alert.success.softphone_online),
				);
			}
		};
		return html`
			<select
				class="phone-type"
				@change=${switchPhoneType}
			>
				${['Webphone', 'Softphone'].map(
					(phone) => html`
						<option
							value=${phone.toLowerCase()}
							?selected=${connectionType === phone.toLowerCase()}
						>
							${phone}
						</option>
					`,
				)}
			</select>
			${when(
				connectionType === 'softphone',
				() => html`
					<iconify-icon
						class="iconify me-1"
						icon="mdi-information-outline"
						width="18"
						@click=${() => {
							openSoftphoneDialog();
						}}
					></iconify-icon>
				`,
				() => html`
					<web-phone
						env="header"
						.user=${User.user.webphoneUser}
						.password=${User.user.webphonePassword}
					></web-phone>
				`,
			)}
			<yd-tooltip .tip=${window.T.hint.connectionPhone[connectionPhone]}>
				<sl-button
					circle
					@click=${setOnline}
					variant=${connectionPhone ? 'success' : 'warning'}
				>
					<iconify-icon
						class="iconify"
						icon=${connectionPhone ? 'mdi-phone-check' : 'mdi-phone-paused'}
					></iconify-icon>
				</sl-button>
			</yd-tooltip>
		`;
	};

	return html`
		<div class="row justify-content-between align-items-center">
			<app-link
				href="/"
				class="logo col-auto"
			>
				<iconify-icon
					icon="ymmd-logo"
					width="115"
				></iconify-icon>
			</app-link>
			<div class="col-auto d-none d-md-flex align-items-center gap-2">
				${User.isOnboarded ? phoneSection() : ''}
			</div>

			<div class="col-auto">
				${when(
					User.user.timezone,
					() => html`
						<div class="d-none d-sm-inline-block">
							${clock(User.user.timezone)}
						</div>
						<select
							class="d-none d-lg-inline-block mx-1 my-0"
							@change=${(e) => setTimezone(e)}
						>
							${Object.keys(i18n.statics?.timezones || {}).map(
								(continent) => html`
									<optgroup label=${continent}>
										${i18n.statics?.timezones[continent].map(
											(country) => html`
												<option
													?selected=${`${continent}/${country}` ===
													User.user.timezone}
													value="${continent}/${country}"
												>
													${country.replaceAll('_', ' ')}
												</option>
											`,
										)}
									</optgroup>
								`,
							)}
						</select>
					`,
				)}
				<app-link href=${'/mailbox'}>
					<mail-indicator></mail-indicator>
				</app-link>
				${when(
					User.user.id,
					() => html`
						<iconify-icon
							icon="material-symbols:help"
							width="24"
							height="24"
							class="iconify pe-4 pointer"
							@click=${toggleSupportPanel}
						></iconify-icon>
					`,
				)}
				<yd-dropdown .direction=${'bottom-right'}>
					<div slot="toggle">
						<user-thumb .user=${User.user}></user-thumb>
					</div>
					<div slot="content">
						<yd-card
							hasBorder
							hasShadow
							class="rounded-0 mt-2 p-2"
						>
							<nav>
								${when(
									User.user.onboardTS || User.isEmployed,
									() => html`
										<app-link href="profile">
											<span>
												<iconify-icon
													icon="mdi-account-circle"
													class="iconify"
													width="20"
												></iconify-icon>
											</span>
											${window.T.nav.profile}
										</app-link>
									`,
								)}
								${when(
									User.user.id,
									() => html`
										<app-link href="account">
											<span>
												<iconify-icon
													icon="mdi-settings"
													class="iconify"
													width="20"
												></iconify-icon>
											</span>
											${window.T.nav.account}
										</app-link>
									`,
								)}
								${when(
									User.user.onboardTS || User.isEmployed,
									() => html`
										<a
											href="#"
											@click=${(e) => {
												e.preventDefault();
												openAppAuthDialog();
											}}
										>
											<span>
												<iconify-icon
													icon="mdi-qrcode-scan"
													class="iconify"
													width="20"
												></iconify-icon>
											</span>
											${window.T.hint.app_auth}
										</a>
									`,
								)}
								<a
									href="#"
									@click=${(e) => {
										e.preventDefault();
										e.currentTarget.dispatchEvent(createLogoutEvent(true));
									}}
								>
									<span>
										<iconify-icon
											icon="mdi-logout"
											class="iconify"
											width="20"
										></iconify-icon>
									</span>
									${window.T.nav.logout}
								</a>
							</nav>
						</yd-card>
					</div>
				</yd-dropdown>
			</div>
		</div>
		${supportPanel}
	`;
};
