import { User } from '@app/User.ts';
import { html } from 'lit';
import { when } from 'lit/directives/when.js';
import { Router } from '@app/router.ts';
import '@shoelace-style/shoelace/dist/components/copy-button/copy-button.js';
import { matomoTracker } from '@modules/matomoTracker.ts';

const navItemTemplate = ({
	href,
	icon,
	txt,
	condition = () => true,
	children = [],
}) => {
	if (!condition()) return '';
	const url = `${Router.getCurrentLocation().url}`;
	const classes =
		(url === '' && href === '/') || (href !== '/' && url.startsWith(href))
			? 'active'
			: '';
	const isExpanded = children.some((subItem) => url.startsWith(subItem.href));
	if (children.length) {
		return html`
			<label for="togglemore">
				<span>
					<iconify-icon
						icon=${icon}
						class="iconify"
					></iconify-icon>
				</span>
				${txt}
			</label>
			<input
				type="checkbox"
				id="togglemore"
				.checked=${isExpanded}
			/>
			<div>${children.map(navItemTemplate)}</div>
		`;
	}
	return when(
		href.startsWith('http'),
		() => html`
			<a
				href=${href}
				class=${classes}
				target="_blank"
			>
				<span>
					<iconify-icon
						icon=${icon}
						class="iconify"
					></iconify-icon>
				</span>
				${txt}
			</a>
		`,
		() => html`
			<app-link
				href=${href}
				class=${classes}
			>
				<span>
					<iconify-icon
						icon=${icon}
						class="iconify"
					></iconify-icon>
				</span>
				${txt}
			</app-link>
		`,
	);
};

const renderNav = (showSalesNavigation = false) => {
	if (User.isHardlocked) return html``;
	const navItems = [
		{
			href: '/',
			icon: 'mdi-monitor-dashboard',
			txt: window.T.nav.workplace,
			matomoEventName: 'tal.nav.main.workspace',
		},
		{
			href: 'learning',
			icon: 'mdi-school-outline',
			txt: window.T.nav.learning,
			matomoEventName: 'tal.nav.main.learningspace',
		},
		{
			href: 'schedule',
			icon: 'mdi-calendar-clock',
			txt: window.T.nav.schedule,
			matomoEventName: 'tal.nav.main.shiftPlanning',
		},
		{
			href: 'calls',
			icon: 'mdi-headset',
			txt: window.T.nav.calls,
			matomoEventName: 'tal.nav.main.callHistory',
		},
		{
			condition: () => User.can('silentmonitor'),
			href: 'monitor',
			icon: 'mdi-video',
			txt: window.T.nav.silent_monitoring,
		},
		{
			href: 'jobs',
			icon: 'mdi-folder',
			txt: window.T.nav.jobs,
			matomoEventName: 'tal.nav.main.myJobs',
		},
		{
			condition: () => User.user.activeCustomers,
			href: 'teams',
			icon: 'mdi-google-circles-communities',
			txt: window.T.nav.teams,
		},
		{
			condition: () => !User.isEmployed,
			href: 'score',
			icon: 'mdi-trophy',
			txt: window.T.nav.gamification,
			matomoEventName: 'tal.nav.main.myAchievements',
		},
		{
			condition: () => !User.isEmployed,
			href: 'joboffers',
			icon: 'mdi-view-module',
			txt: window.T.nav.joboffers,
			matomoEventName: 'tal.nav.main.jobOffers',
		},
		{
			condition: () => !User.isEmployed,
			href: 'income',
			icon: 'mdi-cash-multiple',
			txt: window.T.nav.balance_invoices,
			matomoEventName: 'tal.nav.main.finance',
		},
		{
			condition: () => !User.isEmployed && User.user.referalCode,
			href: 'referrals',
			icon: 'mdi-account-multiple-plus',
			txt: window.T.nav.referrals,
			matomoEventName: 'tal.nav.main.recommendations',
		},
		{
			href: 'qualitychecks',
			icon: 'mdi-clipboard-check-multiple',
			txt: window.T.nav.qualitychecks,
			matomoEventName: 'tal.nav.main.qualityChecks',
		},
		/*
		 * {
		 * href: 'trainings',
		 * icon: 'mdi-school',
		 * txt: window.T.nav.trainings,
		 * },
		 */
		{
			icon: 'mdi-chevron-down',
			txt: window.T.nav.more,
			matomoEventName: 'tal.nav.main.more',
			children: [
				{
					href: 'downloads',
					icon: 'mdi-cloud-download',
					txt: window.T.nav.downloads,
					matomoEventName: 'tal.nav.sub.more.downloads',
				},
				{
					href: 'speed',
					icon: 'mdi-speedometer',
					txt: window.T.nav.speedtest,
					matomoEventName: 'tal.nav.sub.more.speedTest',
				},
				{
					href: 'security',
					icon: 'material-symbols:security-rounded',
					txt: window.T.nav.security,
					matomoEventName: 'tal.nav.sub.more.securityCheck',
				},
				{
					href: window.T.href.faq,
					icon: 'mdi-routes',
					txt: window.T.nav.faq,
					matomoEventName: 'tal.nav.sub.more.faq',
				},
			],
		},
	];

	const navItemsOnboarding = [
		{
			condition: () => !User.isOnboarded,
			href: 'join',
			icon: 'mdi-home-outline',
			txt: window.T.nav.join,
		},
		{
			condition: () => !User.isOnboarded,
			href: `onboarding/${User.obStep}`,
			icon: 'mdi-airplane-takeoff',
			txt: window.T.nav.onboarding,
		},
	];

	const salesToolNavItems = [
		{
			href: 'salestool',
			icon: 'ic:outline-sticky-note-2',
			txt: window.T.nav.reporting,
		},
		{
			condition: () => User.user.isSalesToolAdmin,
			href: 'adminsalestool',
			icon: 'material-symbols:manage-accounts-outline',
			txt: window.T.nav.admin_area,
		},
	];
	let menuItems = User.isOnboarded ? navItems : navItemsOnboarding;
	menuItems = showSalesNavigation ? salesToolNavItems : menuItems;
	return html`
		<nav slot="content">${menuItems.map(navItemTemplate)}</nav>
	`;
};
export const aside = (showSalesNavigation = false) =>
	when(
		User.device.type === 'mobile',
		() => html`
			<yd-dropdown .direction=${'top-left'}>
				<div
					slot="toggle"
					class="burgerbutton"
				>
					<div><span></span></div>
				</div>
				${renderNav()}
			</yd-dropdown>
		`,
		() => html`
			${renderNav(showSalesNavigation)}
			<div class="copy-button">
				<span>${window.T.label.my_id} &nbsp; ${User.user.id}</span>
				<sl-copy-button
					value="${User.user.id}"
					hoist
					copy-label=${window.T.hint.clipboard_copy}
					success-label=${window.T.alert.success.clipboard_copy}
					@click=${() =>
						matomoTracker.track({ name: 'tal.nav.talentId.copy.click' })}
				></sl-copy-button>
			</div>
		`,
	);
