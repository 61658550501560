import { Model } from '@app/Model.js';
import { render, html, nothing } from 'lit';
import { createAlertMessageEvent } from '@components/app-base/custom-events';
import { Log } from '@modules/Log.ts';

export default class Template {
	static isLegacy = true;
	constructor(
		container = document.createElement('div'),
		urlparams = {},
		urlquery = {},
		route = {},
	) {
		this.params = urlparams;
		this.query = urlquery;
		this.container = container;
		this.route = route;
		this.apicalls = {};
		this._rawData = {};
		this.d = {};
		this.intervals = new Set();
		this.name = this.constructor.name;
	}

	mainTemplate() {
		return html``;
	}

	set rawData(obj) {
		// some data is undefined; api returned error;
		if (Object.values(obj).some((d) => !d)) return;
		Object.assign(this._rawData, obj);
		this.processData(Object.keys(obj));
	}

	get rawData() {
		return this._rawData;
	}

	async init() {
		const callsToMake = Object.keys(this.apicalls);
		const results = await Promise.all(
			callsToMake.map(async (call) => ({
				[call]: await Model.data[call](this.apicalls[call]),
			})),
		).catch((err) => {
			Log.error(err);
		});
		// where there apicalls and did they all fail?
		if (
			callsToMake.length &&
			(results.every((r) => !Object.values(r)[0].success) ||
				results.some((r) => Object.values(r)[0].error === 'tfarequired'))
		) {
			return this;
		}
		Object.assign(this.rawData, ...results);
		this.processData();
		this.render();
		return this;
	}

	processData(keys = []) {
		const processKeys = keys.length ? keys : Object.keys(this.rawData);
		processKeys.forEach((k) => {
			const processFunction = `process${k.charAt(0).toUpperCase()}${k.substring(
				1,
			)}`;
			Object.assign(this.d, {
				[k]: this[processFunction] ? this[processFunction]() : this.rawData[k],
			});
		});
		return this;
	}

	render() {
		render(this.mainTemplate(this.d), this.container);
		if (Object.keys(this.d).length) Log.log(this.name, this.d);
		this.ready();
		return this;
	}

	// alias for render(), needed to call a re-render the same way you would with a LitElement/PageBase
	requestUpdate() {
		this.render();
	}
	// alias for destroy(), needed to call a removal the same way you would with a LitElement/PageBase
	remove() {
		this.destroy();
	}

	ready() {}

	destroy() {
		this.render = function () {
			for (const interval of this.intervals) {
				clearInterval(interval);
				this.intervals.delete(interval);
			}
			return false;
		}.bind(this);
		this.ready = function () {
			for (const interval of this.intervals) {
				clearInterval(interval);
				this.intervals.delete(interval);
			}
			return false;
		}.bind(this);
		this.init = function () {
			for (const interval of this.intervals) {
				clearInterval(interval);
				this.intervals.delete(interval);
			}
			return false;
		}.bind(this);

		for (const interval of this.intervals) {
			clearInterval(interval);
			this.intervals.delete(interval);
		}

		Model.abortRunningCalls(Object.keys(this.apicalls));

		[...this.container.getElementsByTagName('form')].forEach((form) =>
			form.reset(),
		);

		this._rawData = {};
		this.d = {};
		render(nothing, this.container);
	}

	showMessage(...args) {
		this.container.dispatchEvent(createAlertMessageEvent(...args));
	}
}
