// prefer deepNestedObjectToURLSearchParams over this if you can
export const deepNestedObjectToQueryString = (object = {}, prefix = '') => {
	const parts = Object.entries(object)
		.map(([key, value]) => {
			const encodedParts = [];
			if (Array.isArray(value)) {
				value.forEach((_a, i) => {
					if (_a instanceof Object) {
						encodedParts.push(
							deepNestedObjectToQueryString(_a, `${prefix}${key}[${i}]`),
						);
					} else {
						encodedParts.push(
							`${prefix}${key}[${i}]=${encodeURIComponent(_a)}`,
						);
					}
				});
			} else if (value instanceof Object) {
				Object.entries(value).forEach(([innerKey, innerValue]) => {
					if (Array.isArray(innerValue)) {
						encodedParts.push(
							deepNestedObjectToQueryString(
								innerValue,
								`${prefix}[${key}][${innerKey}]`,
							),
						);
					} else {
						encodedParts.push(
							`${key}[${innerKey}]=${encodeURIComponent(innerValue)}`,
						);
					}
				});
			} else if (prefix) {
				encodedParts.push(`${prefix}[${key}]=${encodeURIComponent(value)}`);
			} else if (value !== '') {
				// remove empty strings from parameters
				encodedParts.push(`${key}=${encodeURIComponent(value)}`);
			}
			return encodedParts.join('&');
		})
		.filter((v) => !!v);
	return parts.join('&');
};

const isObj = (any) => !!any && any.constructor === Object;
const _st = (z, g) => String(g !== '' ? '[' : '') + z + (g !== '' ? ']' : '');
export const deepNestedObjectToURLSearchParams = (
	params: Record<string, unknown>,
	prefix = '',
) => {
	let result = '';
	if (typeof params !== 'object') {
		return `${prefix}=${encodeURIComponent(params)}&`;
	}
	Object.entries(params).forEach(([k, v]) => {
		const c = String(prefix + _st(k, prefix));
		if (isObj(v)) {
			result = `${result}${deepNestedObjectToURLSearchParams(v, c)}`;
		} else if (Array.isArray(v)) {
			v.forEach((item, ind) => {
				result = `${result}${deepNestedObjectToURLSearchParams(item, `${c}[${ind}]`)}`;
			});
		} else {
			result += `${c}=${encodeURIComponent(v)}&`;
		}
	});
	return result;
};

export const objectToFormData = (object: Record<string, unknown> = {}) => {
	const formData = new FormData();
	Object.entries(object || {}).forEach(([k, v]) => {
		formData.append(k, v);
	});
	return formData;
};
