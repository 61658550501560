import type { YmdRoute } from '@router/types';

export default <Record<string, YmdRoute>>{
	'/worthknowing/:topic': {
		as: 'worthknowing',
		hasAsideNavigation: true,
		hasHeader: true,
		module: () => import('./'),
	},
};
