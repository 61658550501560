import './app-shell';
import { render } from 'lit';
import { User } from './User';
import { i18n } from '@i18n';
import { matomoTracker } from '@modules/matomoTracker';
import '@utils/formSerializeObject.js';
import { globals } from '@globals';
import { Log } from '@modules/Log';
import {
	addAuthenticationErrorHandling,
	addAuthorizationErrorHandling,
	addApiErrorMessages,
} from '@API/middlewares';
import { createLogoutEvent } from '@components/app-base/custom-events';
import { Router, type TalentRoute } from './router';
import { API, APIv2, UploadAPI } from './api';

const app = document.createElement('app-talent');

const errorMessageMiddleWare = addApiErrorMessages(
	i18n.rawText?.alert.error,
	(errorMsg, errorCode, path) => {
		app.onShowMessage({
			detail: {
				variant: 'danger',
				str:
					errorMsg || `${i18n.rawText?.alert.error.unknownerror} ${errorCode}`,
			},
		});

		if (!errorMsg) {
			Log.error(new Error(`API returned error "${errorCode}" calling ${path}`));
		}
	},
);
const authorizationMiddleWare = addAuthorizationErrorHandling(() => {
	app.view?.remove();
	app.onShowMessage({
		detail: {
			variant: 'danger',
			str: i18n.rawText?.alert.error.notallowed || '',
		},
	});
});
const authenticationErrorMiddleWare = addAuthenticationErrorHandling(
	({ schemaPath }) => {
		const onPurpose = schemaPath === '/verifymail';

		app.dispatchEvent(createLogoutEvent(onPurpose));
	},
);

[API, APIv2, UploadAPI].forEach((apiClient) => {
	apiClient.use(errorMessageMiddleWare);
	apiClient.use(authorizationMiddleWare);
	apiClient.use(authenticationErrorMiddleWare);
});

const matomoContainer = `${import.meta.env.VITE_MATOMO_HOSTNAME}${
	globals.isProduction
		? 'container_Y2pxd8H2.js'
		: 'container_Y2pxd8H2_staging_0e14376186e4986bd9957152.js'
}`;

const guiRoutes: Record<string, Record<string, TalentRoute>> = import.meta.glob(
	['/views/**/_routes.ts', './**/_routes.ts'],
	{
		eager: true,
		import: 'default',
	},
);
Object.values(guiRoutes).forEach((routes) => Router.registerRoutes(routes));

const init = async () => {
	let TypingDNA;
	if (!User.device.hasTouchScreen) {
		({ TypingDNA } = await import('@modules/dna.js'));
		// @ts-expect-error TypingDNA can be any, we don't care
		window.Tdna = new TypingDNA();
	}

	try {
		matomoTracker.init(matomoContainer);
		const {
			data: { user = {}, error },
		} = await API.POST('/appinit');
		User.updateUser(
			error === 'tfarequired' ? { id: 'unknown', emailverified: 1 } : user,
		);
	} catch {
		// let's continue without tracker and/or without user
	}

	await i18n.setLanguage(User.preferredLanguage);
	Object.assign(window, {
		T: i18n.txt,
		prismicContent: i18n.prismicContent,
	});
};

init()
	.then(() => {
		render(app, document.body);
	})
	.catch((err) => {
		Log.error(err);
	});
