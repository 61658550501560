import { randomString } from '@utils';
import { Log } from '@modules/Log';
import { APIv2 } from '@app/api';
import { Storage } from '@modules/Storage';
import type ChatLauncher from '../';

type RcMessage = {
	unread: 0 | 1;
	open?: boolean;
	archived?: boolean;
	rid: string;
};
type RcEventMessage = {
	msg: string;
	collection?: unknown;
	fields: { args?: [unknown, RcMessage] };
	result?: Array<RcMessage>;
	id: string;
};

interface RocketChatService {
	widget?: ChatLauncher;
	wsURL: string;
	appURL: string;
	storage: Storage;
	auth?: string;
	initialSubscriptionId: string | null;
	realtimeMessages: Array<RcMessage>;
	instance?: WebSocket;
	errorcount: number;
	restart?: ReturnType<typeof setTimeout>;
	initAndAuthorize: (el?: ChatLauncher) => void;
	getAuth: () => Promise<string>;
	handleMessage: (data: RcEventMessage) => void;
	send: (data: unknown) => void;
	onOpenInstance: () => void;
	onCloseInstance: () => void;
	onMessageInstance: (stringifiedMessage: MessageEvent) => void;
	updateUnreadMessagesCount: () => void;
	getRealtimeMessages: (result: RcEventMessage['result']) => void;
	getSubscriptions: () => void;
	streamNotifyUser: () => void;
	updateRealtimeMessages: (args?: RcMessage) => void;
	openApp: () => void;
}

export const RocketChatService: RocketChatService = {
	appURL: import.meta.env.VITE_ROCKETCHAT_API,
	wsURL: import.meta.env.VITE_ROCKETCHAT_SOCKET,
	storage: new Storage(`${import.meta.env.VITE_APPVERSION}_rocketchat`),
	initialSubscriptionId: '',
	realtimeMessages: [],
	errorcount: 0,
	async initAndAuthorize(widget) {
		this.auth = this.storage.get('token') || (await this.getAuth()) || '';

		this.widget = this.widget || widget;
		clearTimeout(this.restart);
		if (!this.auth) return;

		if (!this.instance) this.instance = new WebSocket(this.wsURL);
		this.instance.onopen = () => {
			this.onOpenInstance();
		};
		this.instance.onclose = () => {
			this.onCloseInstance();
		};
		this.instance.onmessage = (e) => {
			this.onMessageInstance(e);
		};
		this.instance.onerror = () => {
			this.errorcount += 1;
		};
		this.updateUnreadMessagesCount();
	},

	async getAuth() {
		let chatUserId, token;
		try {
			({ data: { token, chatUserId } = {} } =
				(await APIv2.GET(`/chat/user/token`)) || {});
		} catch {
			// no content 204?
		}
		if (chatUserId) this.storage.set('chatUserId', chatUserId);
		if (token) this.storage.set('token', token);
		return token;
	},

	send(msg) {
		this.instance?.send(JSON.stringify(msg));
	},

	onOpenInstance() {
		if ('Notification' in window && Notification.permission === 'default') {
			Notification.requestPermission(() => {});
		}
		this.errorcount = 0;
		this.send({
			msg: 'connect',
			version: '1',
			support: ['1'],
		});
		const auth = this.storage.get('token');
		if (auth) {
			this.send({
				msg: 'method',
				method: 'login',
				id: randomString(),
				params: [{ resume: auth }],
			});
		}
	},

	onCloseInstance() {
		delete this.instance;
		clearTimeout(this.restart);
		this.errorcount += 1;
		this.restart = setTimeout(() => {
			this.initAndAuthorize();
		}, this.errorcount * 5000);
	},

	async handleMessage(data) {
		const { msg, collection, fields, result, id } = data;
		switch (msg) {
			case 'ping':
				this.send({ msg: 'pong' });
				break;
			case 'connected':
				await this.getSubscriptions();
				this.streamNotifyUser();
				break;
			case 'changed':
				if (collection === 'stream-notify-user') {
					this.updateRealtimeMessages(fields?.args?.[1]);
				}
				break;
			case 'result':
				if (id === this.initialSubscriptionId) {
					this.getRealtimeMessages(result);
				}
				break;
			default:
		}
	},

	async onMessageInstance({ data }) {
		let parsedData;
		try {
			parsedData = JSON.parse(data);
		} catch (err) {
			Log.error(err as Error);
			return;
		}
		if (parsedData.error) {
			if (parsedData.error === 'auth') {
				delete this.auth;
			}
			if (parsedData.error === 'timeout') {
				this.errorcount += 1;
			}
		}
		await this.handleMessage(parsedData);
	},

	getSubscriptions() {
		const hashString = randomString();
		this.initialSubscriptionId = hashString;
		this.send({
			msg: 'method',
			method: 'subscriptions/get',
			id: hashString,
			params: [],
		});
	},

	streamNotifyUser() {
		this.send({
			msg: 'sub',
			id: randomString(),
			name: 'stream-notify-user',
			params: [
				`${this.storage.get('chatUserId')}/subscriptions-changed`,
				false,
			],
		});
	},

	getRealtimeMessages(msgs) {
		this.realtimeMessages =
			msgs?.filter(
				(msg) => msg.unread > 0 && !msg.archived && msg.open === true,
			) || [];

		this.updateUnreadMessagesCount();
	},

	updateRealtimeMessages(msg) {
		if (!msg) return;
		const update = { rid: msg.rid, unread: msg.unread };
		this.realtimeMessages = this.realtimeMessages.some(
			(obj) => obj.rid === msg.rid,
		)
			? this.realtimeMessages.map((obj) => (obj.rid === msg.rid ? update : obj))
			: [...this.realtimeMessages, update];

		this.updateUnreadMessagesCount();
	},

	updateUnreadMessagesCount() {
		if (this.widget?.isConnected) {
			this.widget.unreadCount =
				(this.realtimeMessages.reduce(
					(acc, { unread }) => acc + unread,
					0,
				) as number) || 0;
		}
	},
	openApp() {
		this.getAuth();
		window
			.open(`${this.appURL}/home?resumeToken=${this.auth}`, '_blank')!
			.focus();
	},
};
