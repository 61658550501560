import { html, LitElement } from 'lit';
import { matomoTracker } from '@modules/matomoTracker';
import style from './style.scss?inline';
import { customElement, state, property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { Router } from '@app/router';

@customElement('support-panel')
export default class SupportPanel extends LitElement {
	static styles = [style];
	@state()
	private searchQuery: string = '';

	private kb: Window | null = null;

	@property({ type: Boolean, reflect: true, attribute: 'is-open' })
	isOpen: boolean = false;

	searchKnowledgebase = () => {
		if (!this.searchQuery.length) return;
		const cleanedQuery = encodeURIComponent(this.searchQuery);
		const qUrl = `${window.T.href.knowledgebase_search}${cleanedQuery}`;
		if (this.kb && !this.kb.closed) {
			// prevent KB from being opened more than once
			this.kb.location.href = qUrl;
			this.kb.focus();
		} else {
			this.kb = window.open(qUrl);
			matomoTracker.eventTrack(
				'Talent Platform Help',
				'userInteraction',
				'support.knowledgebase.query',
			);
		}
	};

	render() {
		return html`
			${when(
				this.isOpen,
				() => html`
					<div class="support-panel">
						<div class="panel-header">
							<div class="panel-topic">
								<iconify-icon
									icon="material-symbols:help"
									width="24"
									height="24"
									class="iconify"
								></iconify-icon>
								<span class="panel-heading">
									${window.T.headline.platform_support}
								</span>
							</div>
							<div
								class="panel-close"
								@click=${() => {
									this.isOpen = false;
									matomoTracker.eventTrack(
										'Talent Platform Help',
										'userInteraction',
										'support.panel.close',
									);
								}}
							>
								<iconify-icon
									icon="material-symbols:close"
									width="24"
									height="24"
									class="iconify me-2"
								></iconify-icon>
							</div>
						</div>

						<div class="knowledgebase">
							<div class="kbcontent">
								<div class="kbinfo">
									<div class="kbinfoheading">
										${window.T.headline.search_knowledgebase}
									</div>
									<div class="kbinfotext">
										${window.T.notice.knowledgebase_search}
									</div>
								</div>
								<div class="kbsearch">
									<yd-searchfield
										searchonenter
										includesearchbutton
										placeholder=${window.T.placeholder.search_knowledgebase}
										@search=${({ detail: { query } = {} }) => {
											this.searchQuery = query;
											this.searchKnowledgebase();
										}}
										class="w-100"
									></yd-searchfield>
								</div>
							</div>
						</div>

						<div class="footer">
							<div class="footer-header">${window.T.headline.further_help}</div>
							<div class="chat-box">
								<div class="chat-header">${window.T.headline.support_chat}</div>
								<div>${window.T.notice.support_chat}</div>
								<div
									class="chat-cta link"
									@click=${() => {
										Router.navigate('/supportchat');
										this.isOpen = false;
										matomoTracker.eventTrack(
											'Talent Platform Help',
											'userInteraction',
											'support.hubspot.chatbot.open',
										);
									}}
								>
									<iconify-icon
										icon="ic:baseline-forum"
										width="20"
										height="20"
										class="iconify me-2"
									></iconify-icon>
									${window.T.cta.start_chat}
								</div>
							</div>
							<div class="contact-box">
								<div class="contact-header">
									${window.T.headline.support_hotline}
								</div>
								<div>${window.T.notice.contact_us}</div>
								<div class="contact-number">
									<iconify-icon
										icon="ic:baseline-phone"
										width="20"
										height="20"
										class="iconify me-2"
									></iconify-icon>
									+49 89 12 22 32 12 22
								</div>
								<div>${window.T.notice.contact_regular_hours}</div>
							</div>
						</div>
					</div>
				`,
			)}
		`;
	}
}
