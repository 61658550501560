import { talentRouteDefaultConfig, type TalentRoute } from '../../router';
import { Model } from '@app/Model.js';
import { User } from '@app/User';

export const isSalesTool = async (): Promise<void> => {
	const { admin } = await Model.data.salessuccessreportrights();
	if (admin) {
		User.updateUser({ isSalesToolAdmin: admin });
	}
};

export default <Record<string, TalentRoute>>{
	'/salestool': {
		...talentRouteDefaultConfig,
		as: 'salestool',
		hasAsideNavigation: false,
		hasAsideSalesToolNavigation: true,
		hooks: {
			...talentRouteDefaultConfig.hooks,
			before: async (...args) => {
				await isSalesTool();
				talentRouteDefaultConfig.hooks?.before?.(...args);
			},
		},
		module: () => import('./'),
	},
};
