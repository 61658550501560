import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/account': {
		...talentRouteDefaultConfig,
		as: 'account',
		needsOnboarding: false,
		isAllowedWithoutVerifiedEmail: true,
		hasPageTracking: true,
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/account/:settingsgroup': {
		...talentRouteDefaultConfig,
		as: 'account',
		needsOnboarding: false,
		isAllowedWithoutVerifiedEmail: true,
		hasPageTracking: true,
		hasRocketchat: true,
		module: () => import('./'),
	},
};
