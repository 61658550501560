import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import style from './style.scss?inline';

interface ProfileUser {
	id: string;
	userId: string;
	pseudonym: string;
	name: string;
	employment?: 'empl' | 'sol';
	route?: string;
}

@customElement('user-profile')
export default class UserProfile extends LitElement {
	@property({ type: Object }) user: Partial<ProfileUser> = {};

	@property({ type: Boolean }) hidethumb: boolean = false;

	@property({ type: Boolean }) linked: boolean = true;

	@property({ type: String }) subtitle?: string | null = null;

	@property({ type: String }) website?: string | null = null;

	@property({ type: Boolean }) hasCountryFlag?: boolean = true;

	static styles = [style];

	render() {
		const subtitleValue = this.subtitle
			? this.subtitle
			: html`
					<small>${this.user.id || this.user.userId}</small>
				`;
		const subtitle = subtitleValue
			? html`
					<h5 class="subtitle">${subtitleValue}</h5>
				`
			: '';
		const userwebsite = this.website
			? html`
					<a
						href=${this.website}
						class="weblink"
						target="_blank"
					>
						<yd-tooltip tip=${window.T.hint.link_client}>
							<iconify-icon icon="mdi-open-in-new"></iconify-icon>
						</yd-tooltip>
					</a>
				`
			: '';
		if (this.user && !this.user.name) {
			this.user.name = window.T.term.unknown;
		}
		const pseudonymTmpl =
			this.user && this.user.pseudonym
				? html`
						<span class="pseudonym">${this.user.pseudonym}</span>
					`
				: '';

		return this.user
			? html`
					<div class="user-in-list">
						${this.hidethumb
							? ''
							: html`
									<div class="thumb">
										${this.linked
											? html`
													<a
														href="talent/${this.user.id || this.user.userId}"
														target="_blank"
														title=${this.user.name}
													>
														<user-thumb
															.user=${this.user}
															.hasCountry=${!!this.hasCountryFlag}
														></user-thumb>
													</a>
												`
											: html`
													<user-thumb
														.user=${this.user}
														.hasCountry=${!!this.hasCountryFlag}
													></user-thumb>
												`}
									</div>
								`}
						<div class="talent-info">
							${this.linked && !this.website
								? html`
										<a
											href="${this.user.route || 'talent'}/${this.user.id ||
											this.user.userId}"
											class="talent-name"
											target="_blank"
											title=${this.user.name || ''}
										>
											<span class="talent-realname">${this.user.name}</span>
											<br />
											${pseudonymTmpl} ${subtitle}
										</a>
									`
								: html`
										<span class="talent-name">
											<span class="talent-realname">${this.user.name}</span>
											${userwebsite}
											<br />
											${pseudonymTmpl} ${subtitle}
										</span>
									`}
							${this.user.employment === 'empl'
								? html`
										<yd-tooltip tip=${window.T.api.employment.empl}>
											<yd-badge variant="info">
												<iconify-icon
													icon="mdi-briefcase"
													class="iconify"
												></iconify-icon>
											</yd-badge>
										</yd-tooltip>
									`
								: ''}
						</div>
					</div>
				`
			: html`
					<span class="hidden">zzz</span>
					<div class="row g-0 align-items-center">
						<div class="col-auto">
							<div class="thumb">
								<iconify-icon
									icon="mdi-account"
									class="iconify"
								></iconify-icon>
							</div>
						</div>
						<div class="col">
							<i class="subtile">${window.T.term.deleted_user}</i>
						</div>
					</div>
				`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'user-profile': UserProfile;
	}
}
