import type { YmdRoute } from '@router/types';

export default <Record<string, YmdRoute>>{
	'/mobiledocupload/:token': {
		as: 'mobiledocupload',
		isAuthRequired: false,
		isAllowedWithoutVerifiedEmail: true,
		hasPageTracking: true,
		hasHeader: false,
		hasAsideNavigation: false,
		module: () => import('./'),
	},
};
