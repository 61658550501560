/* eslint-disable no-undefined */
declare global {
	interface Window {
		turnstile?: Turnstile.Turnstile;
	}
}

export type TurnstileWidget = {
	src: string;
	turnstileLib?: Turnstile.Turnstile;
	container: HTMLDivElement;
	widgetId?: string;
	token: string | null;
	config: Turnstile.RenderParameters;
	errorCounter: number;
	appendScript: (onload: (value: boolean) => void, onerror: () => void) => void;
	renderTurnstile: (
		container: HTMLElement,
	) => Promise<TurnstileWidget | undefined>;
	remove: () => void;
	reset: () => void;
};

export const turnstileWidget: TurnstileWidget = {
	src: 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit',
	token: 'no-token',
	container: Object.assign(document.createElement('div'), {
		slot: 'captcha',
	}),
	widgetId: '',
	errorCounter: 0,
	config: {
		sitekey: import.meta.env.VITE_CF_TURNSTILE_SITEKEY,
		theme: 'light',
		'response-field': false,
		callback: (token: string) => {
			turnstileWidget.token = token;
			turnstileWidget.container?.dispatchEvent(
				new CustomEvent('turnstile-token-success', {
					bubbles: true,
					composed: true,
				}),
			);
			turnstileWidget.errorCounter = 0;
		},
		'error-callback': (error: string) => {
			turnstileWidget.token = '';
			if (
				['102', '103', '104', '106', '300', '600', '11062', '11060'].some(
					(code) => error.startsWith(code),
				)
			) {
				turnstileWidget.errorCounter += 1;
			}
			if (turnstileWidget.errorCounter >= 3) {
				turnstileWidget.reset();
				throw new Error(`Error :  ${error}`);
			}
		},
	},
	appendScript(done, onerror) {
		if (window.turnstile) {
			done(true);
			return;
		}
		const script = document.createElement('script');
		script.src = this.src;
		script.defer = true;
		script.onerror = onerror;
		script.onload = () => {
			this.turnstileLib = window.turnstile;
			done(true);
		};
		document.head.appendChild(script);
	},
	async renderTurnstile(parentElement) {
		if (!parentElement) return undefined;
		if (!this.container.isConnected) {
			parentElement.append(this.container);
		}
		const loaded = await new Promise((resolve, reject) => {
			this.appendScript(resolve, reject);
		});
		if (!loaded) return undefined;

		this.widgetId =
			this.widgetId ||
			this.turnstileLib?.render(this.container, this.config) ||
			'';
		return this;
	},
	remove() {
		this.turnstileLib?.remove(this.container);
		this.container.remove();
		this.widgetId = undefined;
	},
	reset() {
		this.token = '';
		this.turnstileLib?.reset(this.widgetId);
	},
};
