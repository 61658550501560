import { header } from './header.js';
import { aside } from './aside.js';
import { customElement, query, state } from 'lit/decorators.js';
import { render, html, nothing } from 'lit';
import { socket, type PushMsg } from '@modules/Socket';
import { Log } from '@modules/Log.js';
import { User } from './User';
import { i18n } from '@i18n';
import { notification, br } from '@utils';
import { Storage } from '@modules/Storage';
import Template from '../Template.js';
import { when } from 'lit/directives/when.js';
import { classMap } from 'lit/directives/class-map.js';
import { audioControls } from '../AudioPlayer.js';
import '@components/chat-launcher';
import AppBase from '@components/app-base';
import { createNavigateEvent } from '@components/app-base/custom-events';
import '@utils/formSerializeObject.js';
import { API } from './api';
import { Router } from './router';
import type { TalentRoute } from './router.js';
import type { PageBase } from '@components/page-base';

@customElement('app-talent')
export default class AppTalent extends AppBase {
	view?: PageBase | Template;

	@state() route?: TalentRoute;

	// deprecated: modals/dialogs/whatnot should be replaced with yd-dialog and handled in their calling views
	@query('.modal') modalElement!: HTMLElement;
	modal?: Template & { name: string };

	overlay?: (Template & { name: string }) | null;
	@query('main.overlay') overlayElement!: HTMLElement;

	// deprecated: legacy container is for Template Class Instance
	@query('#legacy-page') legacyContainer!: HTMLElement;

	localStorage = new Storage(this.nameSpace, 'local');

	seenPushes: string[] = [];

	connectedCallback() {
		this.addEventListeners();
		super.connectedCallback();
		Router.subscribe(async (route, params, queryParams) => {
			if (!route.routeDefaultExport) return;
			if (this.view instanceof Template) this.view.remove();
			if (route.routeDefaultExport.isLegacy) {
				this.view = new route.routeDefaultExport(
					// eslint-disable-next-line no-undefined
					undefined,
					params || {},
					queryParams || {},
					route,
				) as Template;
				if (!this.view) return;

				render(nothing, this);
				render(this.view.container, this.legacyContainer);
				await this.view.init();
			} else {
				this.view = new route.routeDefaultExport();
				if (!this.view) return;
				this.view.params = params || {};
				this.view.query = queryParams || {};
				this.view.route = route;
				render(nothing, this.legacyContainer);
				render(this.view, this);
			}

			this.route = route;
		});
	}

	addEventListeners() {
		socket.Log = Log;
		socket.subscribe(
			this,
			'callstate',
			({ detail: { calling, reason = '', connectionPhone = 1 } }) => {
				if (
					!calling &&
					reason &&
					i18n.rawText?.alert.error.telephony &&
					reason in i18n.rawText.alert.error.telephony
				) {
					if (connectionPhone === 0) {
						// backend has set the user to connectionPhone: 0, so we do the same
						User.updateUser({ connectionPhone });
						this.showDialog({
							confirmButtonText: window.T.cta.close,
							noDismissButton: true,
							variant: 'danger',
							titleText: window.T.term.error,
							html: html`
								${window.T.alert.error.telephony.NO_ANSWER}
								<br />
								<small>[${reason}]</small>
							`,
						});
						this.requestUpdate();
					} else if (reason !== 'NO_ANSWER') {
						this.showDialog({
							confirmButtonText: window.T.cta.close,
							noDismissButton: true,
							variant: 'danger',
							titleText: window.T.term.error,
							html:
								reason === 'UNALLOCATED_NUMBER' &&
								User.user?.connectionType !== 'webphone'
									? window.T.alert.error.telephony.UNALLOCATED_NUMBER_sipphone
									: html`
											${window.T.alert.error.telephony[reason]}
											<br />
											<small>[${reason}]</small>
										`,
						});
					}
				}
			},
		);
		socket.subscribe(this, 'call', ({ detail }) => {
			if (this.localStorage.get('currentCallId') === detail.callId) return;
			this.localStorage.set('currentCallId', detail.callId);

			notification(
				window.T.message.event.call_new,
				`${detail.user?.company} | ${detail.module?.title}`,
				i18n.language,
			);

			this.dispatchEvent(
				new CustomEvent('showOverlay', {
					detail: {
						name: 'call',
						data: { call: detail },
					},
				}),
			);
		});
		socket.subscribe(this, 'push', ({ detail: { msgs = [] } }) => {
			if (
				!msgs.some(
					({ answer, id }) =>
						!answer && // not answered yet
						!(this.localStorage.get('seenPushes') || []).includes(id), // not yet seen
				)
			) {
				return;
			}
			const singlePush = (push: PushMsg) => {
				const container = document.createElement('div');
				const react = async (pnId: string, answer: 'no' | 'yes') => {
					const { data: { pns = [] } = {} } = await API.POST(
						'/notificationanswer',
						{
							body: {
								answer,
								pnId,
							},
						},
					);
					if (pns.every((pn) => !!pn.answer)) {
						this.onDestroyToast();
					} else {
						container.remove();
					}
					this.localStorage.push('seenPushes', push.id);
				};
				render(
					html`
						<div style="display:flex; align-items:center;">
							<div>
								<user-thumb
									.user=${push.user || { ymdItself: true }}
									size="40"
								></user-thumb>
							</div>
							<div>
								<h5 class="m-0">${push.title}</h5>
							</div>
						</div>
						<p>${br(push.text)}</p>
						${when(
							push.user,
							() => html`
								<p>
									<sl-button
										@click=${() => react(push.id, 'no')}
										variant="danger"
									>
										<iconify-icon
											icon="mdi-close-circle"
											slot="prefix"
										></iconify-icon>
										${window.T.cta.decline}
									</sl-button>
									<sl-button
										@click=${react}
										variant="success"
										data-answer="yes"
									>
										<iconify-icon
											icon="mdi-check-circle"
											slot="prefix"
										></iconify-icon>
										${window.T.cta.agree}
									</sl-button>
								</p>
							`,
						)}
					`,
					container,
				);
				return container;
			};
			notification(window.T.message.event.pushmsg, '', i18n.language);
			msgs.forEach((push) => {
				const container = singlePush(push);
				this.onShowToast(
					new CustomEvent('showToast', {
						detail: {
							element: container,
							variant: 'neutral',
							onDismissed: () => {
								this.localStorage.push('seenPushes', push.id);
							},
						},
					}),
				);
			});
		});

		this.addEventListener('showOverlay', ({ detail }) => {
			const { name, data = {} } = detail;
			if (this.overlay && this.overlay.name !== name) this.overlay.destroy();
			import(`@app/pages/${name}.js`).then(({ default: Overlay }) => {
				this.overlay = new Overlay(this.overlayElement, null, null, this, name);
				if (this.overlay) {
					this.overlay._rawData = {
						...this.overlay?._rawData,
						...data,
					};
				}
				this.overlay?.init();
				this.requestUpdate();
			});
		});
		this.addEventListener('destroyOverlay', () => {
			this.overlay?.destroy();
			this.overlay = null;
			this.requestUpdate();
		});
		/*
		 * DEPRECATED needs to be refactored to dialog!
		 * Modal
		 */
		this.addEventListener('showModal', ({ detail }) => {
			const { name, data } = detail;
			import(`@app/modal/${name}.js`).then(({ default: Modal }) => {
				this.modal = new Modal(this.modalElement, data);
				this.modal?.init();
			});
		});
		this.addEventListener('destroyModal', () => {
			this.modal?.destroy();
			delete this.modal;
		});

		// [data-for] and [name="tabs-*"] evt listening should be replaced with something new and <yd-tabs>
		this.shadowRoot?.addEventListener('change', (e) => {
			const target = e.target as HTMLInputElement;
			if (typeof target.name === 'string' && target.name?.startsWith('tab')) {
				this.shadowRoot?.querySelectorAll('[for^="tab"]').forEach((forTab) => {
					const activateTab = this.shadowRoot?.querySelector(
						`#${forTab.getAttribute('for')}`,
					) as HTMLInputElement;
					if (activateTab && 'checked' in activateTab) {
						forTab.classList.toggle('active', activateTab.checked);
					}
				});
			}
		});
	}

	onLogout({ detail: onPurpose }: CustomEvent<boolean>) {
		delete User.user;
		Router.navigate('/login', {
			updateBrowserURL: false,
		});
		socket.kill();
		sessionStorage.clear();
		if (!onPurpose) {
			this.showSnackBar({
				str: window.T.alert.error.auth,
				variant: 'danger',
			});
		} else {
			API.POST('/logout');
		}
	}

	onNavigate(e: ReturnType<typeof createNavigateEvent>) {
		Router.navigate(e.detail);
	}

	// just a bridge for legacy Model-Class
	userUpdated(user: APItalent) {
		User.updateUser(user);
	}

	render() {
		return html`
			<article class=${classMap({ 'has-header': !!this.route?.hasHeader })}>
				${when(
					User.hasSession,
					() => html`
						${when(
							this.route?.hasAsideNavigation ||
								this.route?.hasAsideSalesToolNavigation,
							() => html`
								<aside>${aside(this.route?.hasAsideSalesToolNavigation)}</aside>
							`,
						)}
						<main class="overlay ${classMap({ open: !!this.overlay })}"></main>
					`,
				)}

				<main>
					<slot name="hubspot-chatbot"></slot>
					${audioControls}
					<section
						class=${classMap({
							'no-padding':
								!this.route?.hasHeader && !this.route?.hasAsideNavigation,
						})}
					>
						${when(
							this.isLoading,
							() => html`
								<div class="loading-bar"></div>
							`,
						)}
						<slot></slot>
						<div id="legacy-page"></div>
					</section>
				</main>
				${when(
					User.hasSession && this.route?.hasRocketchat,
					() => html`
						<chat-launcher></chat-launcher>
					`,
				)}
			</article>

			${when(
				this.route?.hasHeader && User.hasSession,
				() => html`
					<header class="talent">${header()}</header>
				`,
			)}

			<div class="modal"></div>

			${when(this.dialog, () => this.renderDialog())}
			<!---->
			${this.renderSnackBar()}
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'app-talent': AppTalent;
	}
	interface HTMLElementEventMap {
		showModal: CustomEvent<{ name: string; data: Record<string, unknown> }>;
		destroyModal: CustomEvent;
		showOverlay: CustomEvent<{ name: string; data: Record<string, unknown> }>;
		destroyOverlay: CustomEvent;
	}
}
