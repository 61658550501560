import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/jobs/:settingsgroup': {
		...talentRouteDefaultConfig,
		as: 'jobs',
		module: () => import('./'),
	},
	'/jobs': {
		...talentRouteDefaultConfig,
		as: 'jobs',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
