import type { YmdRoute } from '@router/types';

export default <Record<string, YmdRoute>>{
	'/webphone': {
		as: 'webphone',
		hasAsideNavigation: false,
		hasHeader: false,
		module: () => import('./'),
	},
};
