import { customElement, property } from 'lit/decorators.js';
import { css, html, LitElement } from 'lit';
import { createNavigateEvent } from '../app-base/custom-events';

@customElement('app-link')
export default class AppLink extends LitElement {
	@property({ type: String }) href?: string;

	static styles = css`
		:host {
			cursor: pointer;
		}
		a {
			color: inherit;
			text-decoration: inherit;
		}
	`;

	connectedCallback(): void {
		super.connectedCallback();
		this.addEventListener('click', () => {
			if (this.href) {
				this.dispatchEvent(createNavigateEvent(this.href));
			}
		});
	}

	void(e: MouseEvent) {
		e.preventDefault();
	}

	render() {
		return html`
			<a
				@click=${this.void}
				href=${this.href || '#'}
			>
				<slot></slot>
			</a>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'app-link': AppLink;
	}
}
