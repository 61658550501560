import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/supportchat': {
		...talentRouteDefaultConfig,
		as: 'supportchat',
		needsOnboarding: false,
		hasPageTracking: true,
		module: () => import('./'),
	},
};
