/* eslint-disable no-console */
export const Log: {
	debug: boolean;
	log: (...args: unknown[]) => void;
	error: (err: Error | string) => void;
} = {
	get debug() {
		return sessionStorage.getItem('Log.debug') === '1';
	},

	set debug(bool) {
		const debugModeNumber = Number(bool);
		sessionStorage.set('Log.debug', debugModeNumber);
	},
	log(...args) {
		if (this.debug) console.log(...args);
	},
	error(err) {
		// @ts-expect-error Sentry might be declared on window or not
		window.Sentry?.captureException(err);
		console.error(err);
	},
};
