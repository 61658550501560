import { LitElement, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { customElement, state } from 'lit/decorators.js';
import { RocketChatService } from './services/RocketChat';
import style from './style.scss?inline';
import { when } from 'lit/directives/when.js';

@customElement('chat-launcher')
export default class ChatLauncher extends LitElement {
	static styles = [style];

	@state() unreadCount: number = 0;
	@state() collisionPrevention: boolean = false;

	async connectedCallback(): Promise<void> {
		await RocketChatService.initAndAuthorize(this);
		super.connectedCallback();
	}

	render() {
		return when(
			RocketChatService.auth,
			() => html`
				<div
					class="chat ${classMap({
						'prevent-collision': this.collisionPrevention,
					})}"
				>
					<sl-button
						@click=${() => RocketChatService.openApp()}
						size="large"
						class="fab"
						circle
					>
						<iconify-icon
							id="chaticon"
							class="chaticon"
							icon="mdi:chat-bubble-outline"
							width="1.4rem"
							height="1.4rem"
						></iconify-icon>
						<iconify-icon
							id="chaticon2"
							class="chaticon"
							icon="mdi:external-link"
							width="1.4rem"
							height="1.4rem"
						></iconify-icon>
					</sl-button>
					${when(
						Number(this.unreadCount) > 0,
						() => html`
							<span>${this.unreadCount >= 99 ? '99+' : this.unreadCount}</span>
						`,
					)}
				</div>
			`,
		);
	}
}
