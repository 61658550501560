import type {
	ShowMessageEventArguments,
	ShowToastMessageEventArguments,
	ShowMessageEventDetails,
	ShowToastMessageEventDetails,
} from './types';

export const nameSpace: {
	_value: `${string}-${string}`;
	value: `${string}-${string}`;
} = {
	_value: 'app-shell',
	get value() {
		return this._value;
	},
	set value(ns: `${string}-${string}`) {
		this._value = ns;
	},
};

const customEventDefaultConfig = {
	bubbles: true,
	composed: true,
};

export const createAlertMessageEvent: (
	...args: ShowMessageEventArguments
) => CustomEvent<ShowMessageEventDetails> = (str, variant = 'success') =>
	new CustomEvent(`${nameSpace.value}-showMessage`, {
		detail: { str, variant },
		...customEventDefaultConfig,
	});

export const createRerenderEvent = (): CustomEvent =>
	new CustomEvent(`${nameSpace.value}-rerender`, customEventDefaultConfig);

export const createToastMessageEvent: (
	...args: ShowToastMessageEventArguments
) => CustomEvent<ShowToastMessageEventDetails> = (
	element,
	variant,
	onDismissed = () => {},
) =>
	new CustomEvent(`${nameSpace.value}-showToast`, {
		detail: { element, variant, onDismissed },
		...customEventDefaultConfig,
	});

export const createDestroyToastMessageEvent: () => CustomEvent = () =>
	new CustomEvent(`${nameSpace.value}-destroyToast`, customEventDefaultConfig);

export const createNavigateEvent: (href: string) => CustomEvent<string> = (
	href,
) =>
	new CustomEvent(`${nameSpace.value}-navigate`, {
		detail: href,
		...customEventDefaultConfig,
	});

export const createLogoutEvent: (
	onPurpose?: boolean,
) => CustomEvent<boolean> = (onPurpose) =>
	new CustomEvent(`${nameSpace.value}-logout`, {
		detail: onPurpose,
		...customEventDefaultConfig,
	});

declare global {
	interface HTMLElementEventMap {
		navigate: ReturnType<typeof createNavigateEvent>;
		logout: ReturnType<typeof createLogoutEvent>;
		showMessage: ReturnType<typeof createAlertMessageEvent>;
		showToast: ReturnType<typeof createToastMessageEvent>;
		destroyToast: ReturnType<typeof createDestroyToastMessageEvent>;
		rerender: ReturnType<typeof createRerenderEvent>;
	}
}
