/* eslint-disable no-use-before-define */
import moment from 'moment-timezone';
import { html, render } from 'lit';

const audioControls = document.createElement('div');
audioControls.classList.add('audiocontrols', 'hidden');

const audio = new Audio();
const paint = () => {
	render(audioControlTpl(), audioControls);
};
const stop = () => {
	audio.pause();
	audio.currentTime = 0;
	audio.src = '';
	render(audioControlTpl(), audioControls);
	audioControls.classList.add('hidden');
};
const seek = (e) => {
	audio.currentTime = parseInt(e.currentTarget.value);
};

audio.onended = stop;
audio.oncanplaythrough = paint;
audio.ontimeupdate = paint;

const startAudio = (src = '', descr = '') => {
	if (!src) return;
	audio.src = src;
	audio.descr = descr;
	audio.play();
	audioControls.classList.remove('hidden');
	render(audioControlTpl(descr), audioControls);
};

const audioControlTpl = () => html`
	<div class="row align-items-center">
		<div class="col">
			${!audio.src.includes('/stream/')
				? html`
						<input
							class="form-range"
							.value=${audio.currentTime || 0}
							max=${audio.duration}
							step="0.001"
							type="range"
							@input=${seek}
							@click=${(e) => e.preventDefault()}
						/>
					`
				: ''}
			<div class="row">
				<div class="col ellipsis">
					<small class="currentcall">${audio.descr || '&nbsp;'}</small>
				</div>
				<div class="col-auto">
					${audio.currentTime
						? moment
								.utc(
									moment
										.duration(parseInt(audio.currentTime), 'seconds')
										.as('milliseconds'),
								)
								.format('mm:ss')
						: '00:00'}
				</div>
			</div>
		</div>
		<div class="col-auto">
			<yd-tooltip tip=${window.T.hint.audiocontrols_stop}>
				<sl-button
					circle
					@click=${stop}
				>
					<iconify-icon
						icon=${audio.readyState > 0 ? 'mdi-close' : 'mdi-dots-horizontal'}
						class="iconify"
					></iconify-icon>
				</sl-button>
			</yd-tooltip>
		</div>
	</div>
`;

export { audioControls, startAudio };
