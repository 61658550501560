import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/form': {
		...talentRouteDefaultConfig,
		as: 'form',
		needsOnboarding: false,
		isAuthRequired: false,
		isAllowedWithoutVerifiedEmail: true,
		hasPageTracking: false,
		module: () => import('.'),
	},
};
