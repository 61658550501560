
			import 'normalize.css';
			import '@frontend/yd-components';
			import '@shoelace-style/shoelace/dist/components/alert/alert.js';
			import '@shoelace-style/shoelace/dist/components/button/button.js';
			import '@components/app-link';
			import '@components/track-link';
			import '@components/user-profile';
			import '@components/user-thumb';
			import '@app/index.ts';

			window.sentryOnLoad = function () {
				Sentry?.init({
					release: import.meta.env.VITE_APPVERSION,
					environment: window.location.hostname,
					enabled: !!Number(import.meta.env.VITE_SENTRY_ON),
					allowUrls:
						/https:\/\/((talent|manage|onboarding)(\.|-qa\.))?yoummday\.com/,
					maxBreadcrumbs: 30,
					initialScope(scope) {
						scope.setExtra(
							'Unsupported Browser Functions',
							Object.keys(window.supportedBrowserFunctions)
								.filter((feature) => !window.supportedBrowserFunctions[feature])
								.join(', ') || '-',
						);
						return scope;
					},
				});
			};
		