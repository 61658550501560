import ModelClass from '../Model.js';

export default class ModelTalent extends ModelClass {
	apiHost = `${import.meta.env.VITE_API_HOST}provider/`;
	onAPIpriceCheckLowError(error, path, response) {
		this.resendAfterForceDialog(error, path, response);
	}
}

const Model = new ModelTalent();
export { Model };
