import * as prismic from '@prismicio/client';
import moment from 'moment-timezone';
import 'moment/dist/locale/de.js';
import { Log } from '@modules/Log';
import { API } from '@app/api';
import { Storage } from '@modules/Storage';
import { recursiveJSONProxy } from './utils/recursiveJSONProxy';
import type languageJSON from './srcs/en.json';
import type { AppStatics, prismicContent } from './types';
import type Descriptions from './srcs/en.descriptions.prismic.json';
import type QuickTips from './srcs/en.quick_tip.prismic.json';
import type InfoCard from './srcs/en.information_card.prismic.json';
import type FAQgroup from './srcs/en.faq_group.prismic.json';
import type Insigths from './srcs/en.insights.prismic.json';
import type News from './srcs/en.news.prismic.json';
import type WorthKnowing from './srcs/en.worth_knowing.prismic.json';
import type Training from './srcs/en.yd_training.prismic.json';

const prismicJSONs = import.meta.glob([`./srcs/*.prismic.json`], {
	import: 'default',
});

const euVatIdRequired = [
	'AT',
	'BE',
	'BG',
	'CY',
	'CZ',
	'DK',
	'EE',
	'GR',
	'ES',
	'FI',
	'FR',
	'HR',
	'HU',
	'IE',
	'IT',
	'LT',
	'LU',
	'LV',
	'MT',
	'NL',
	'PL',
	'PT',
	'RO',
	'SE',
	'SI',
	'SK',
	'CH', // CH ?!?
];
const euCountries = [...euVatIdRequired, 'DE'];
const ustCountries = [...euCountries, 'GB'];
const highlyDemandedLanguages = ['de', 'en', 'nl', 'fr', 'it', 'es'];

export class Internationalization {
	txt: typeof languageJSON | null = null;
	rawText?: typeof languageJSON;
	statics?: AppStatics;
	storage = new Storage(`${import.meta.env.VITE_APPVERSION}_i18n`);
	language: AppStatics['lang'] = 'en';
	translations: Record<AppStatics['lang'], typeof languageJSON | null> = {
		de: null,
		en: null,
	};
	prismicContent?: prismicContent;
	prismicClient: prismic.Client<
		prismic.PrismicDocument<Record<string, never>, string, string>
	> = prismic.createClient('ymmd');

	async fetchTextSources() {
		try {
			this.translations[this.language] = await import(
				`./srcs/${this.language}.json`
			);

			const {
				quick_tip: quickTip,
				information_card: infoCard,
				descriptions,
				faq_group: faqs,
				worth_knowing: worthKnowing,
				insights,
				news,
				yd_training: trainings,
			}: {
				descriptions: typeof Descriptions;
				quick_tip: typeof QuickTips;
				faq_group: typeof FAQgroup;
				information_card: typeof InfoCard;
				news: typeof News;
				worth_knowing: typeof WorthKnowing;
				yd_training: typeof Training;
				insights: typeof Insigths;
			} = Object.fromEntries(
				await Promise.all(
					Object.entries(prismicJSONs)
						.filter(([path]) => path.includes(`srcs/${this.language}`))
						.map(async ([path, json]) => [path.split('.')[2], await json()]),
				),
			);

			this.prismicContent = {
				quickTips: Object.fromEntries(
					quickTip.map(({ uid, data }) => [uid, data]),
				),
				informationCard: Object.fromEntries(
					infoCard.map(({ uid, data }) => [uid, data]),
				),
				descriptions: Object.fromEntries(
					descriptions.map(({ uid, data }) => [
						uid,
						Object.fromEntries(
							data.pair.map(({ key, title, description, public: visible }) => [
								key,
								{ title, description, visible },
							]),
						),
					]),
				),
				faqGroup: Object.fromEntries(faqs.map(({ uid, data }) => [uid, data])),
				worthKnowings: Object.fromEntries(
					worthKnowing.map(({ uid, data }) => [uid, data]),
				),
				insights: insights.map(({ data }) => data),
				news: news.map(({ data, uid, last_publication_date: date }) => ({
					...data,
					date,
					uid,
				})),
				trainings: trainings.map(({ data, last_publication_date: date }) => ({
					...data,
					date,
				})),
			};
		} catch (err) {
			Log.error(err);
		}
	}

	sortCountryCodes(
		countries: Record<string, string> = {},
		countrycodes: Record<string, string[]> = {},
	) {
		return Object.fromEntries(
			Object.entries(countrycodes).sort(([aCountryKey], [bCountryKey]) =>
				countries[aCountryKey]
					? countries[aCountryKey].localeCompare(countries[bCountryKey])
					: -1,
			),
		);
	}

	async getTranslatedStaticDataFromBackend() {
		let data;
		if (this.storage.has(this.language)) {
			data = this.storage.get(this.language);
		} else {
			try {
				({ data } = await API.POST('/statics', {
					body: {
						lang: this.language,
					},
				}));
				this.storage.set(this.language, data);
			} catch {
				// nvm
			}
		}

		return {
			...(!data || data?.error ? {} : data),
			ustCountries,
			euCountries,
			euVatIdRequired,
			lang: this.language,
			highlyDemandedLanguages,
			countrycodes: this.sortCountryCodes(data?.countries, data?.countrycodes),
		};
	}

	isSupported(language: string): boolean {
		return Object.keys(this.translations).some((l) => l === language);
	}

	async setLanguage(incomingLanguage: string = this.language) {
		const wantedLanguage = incomingLanguage.substring(0, 2).toLowerCase();
		let isNewLanguage = false;
		if (this.isSupported(wantedLanguage)) {
			isNewLanguage = this.language !== wantedLanguage;
			this.language = wantedLanguage as AppStatics['lang'];
		}
		moment.locale(this.language);

		if (!this.translations[this.language]) {
			await this.fetchTextSources();
		}
		this.rawText = this.translations[this.language]!;
		this.txt = recursiveJSONProxy(this.rawText || {});

		this.statics = await this.getTranslatedStaticDataFromBackend();

		return {
			txt: this.txt,
			prismicContent: this.prismicContent,
			isNewLanguage,
		};
	}
}

export const i18n = new Internationalization();

declare global {
	interface Window {
		T: typeof languageJSON;
	}
}
