import type { YmdRoute } from '@router/types';

export default <Record<string, YmdRoute>>{
	'/pwreset/:token': {
		as: 'pwreset',
		hasHeader: false,
		hasAsideNavigation: false,
		isAuthRequired: false,
		hasPageTracking: true,
		module: () => import('./'),
	},
};
