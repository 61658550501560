export type PushMsg = {
	id: string;
	title: string;
	text: string;
	answer?: 0 | 1;
	user?: APIcompany;
};

type SocketMessage = {
	type: 'push' | 'call' | 'callstate' | 'callqueue' | '*';
	error?: string;
	unreadmessages?: number;
	callId?: string;
	module?: {
		title: string;
	};
	user?: {
		company: string;
	};
	calling?: 0 | 1;
	connectionPhone?: 0 | 1;
	reason?: string;
	msgs?: PushMsg[];
	queues?: Record<string, Record<string, { wait: never[]; caller: never[] }>>;
	ts: number; // unix timestamp
};

interface Socket {
	Log: {
		error: (err: Error | string) => void;
		log: (...args: unknown[]) => void;
	};
	wsURL: string | null;
	instance?: WebSocket;
	errorcount: number;
	auth?: string;
	restart?: ReturnType<typeof setTimeout>;
	onMessage: (arg0: MessageEvent<string>) => void;
	onClose: () => void;
	onOpen: () => void;
	initAndAuthorize: (auth?: string, wsURL?: string) => void;
	subscriptions: Record<
		string,
		Map<HTMLElement, (e: CustomEvent<SocketMessage>) => void>
	>;
	subscribe: (
		subscriber: HTMLElement,
		eventName: SocketMessage['type'],
		listener: (e: CustomEvent<SocketMessage>) => void,
	) => () => void;
	callSubscriber: (subscriber: HTMLElement, parsedData: SocketMessage) => void;
	event: (eventData: SocketMessage) => CustomEvent<SocketMessage>;
	removeFromSubscriptions: (
		eventName: SocketMessage['type'],
		subscriber: HTMLElement,
	) => void;
	kill: () => void;
}

export const socket: Socket = {
	Log: console,
	wsURL: null,
	errorcount: 0,
	subscriptions: {},
	event: (eventData) =>
		new CustomEvent(`websocket-msg-${eventData.type || '*'}`, {
			detail: eventData,
		}),
	subscribe(subscriber, eventName, listener) {
		this.subscriptions[eventName] = this.subscriptions[eventName] || new Map();
		this.subscriptions[eventName].set(subscriber, listener);
		subscriber.addEventListener(`websocket-msg-${eventName}`, listener);

		return () => this.removeFromSubscriptions(eventName, subscriber);
	},
	callSubscriber(subscriber, parsedData) {
		let successfullyDispatched = false;
		if (subscriber?.isConnected) {
			try {
				subscriber.dispatchEvent(this.event(parsedData));
				successfullyDispatched = true;
			} catch (err) {
				this.Log.error(err as Error);
			}
		}
		if (!successfullyDispatched) {
			const { type: eventName = '*' } = parsedData;
			this.removeFromSubscriptions(eventName, subscriber);
		}
	},
	removeFromSubscriptions(eventName, subscriber) {
		try {
			const subscriptions = this.subscriptions[eventName];
			subscriber.removeEventListener(
				`websocket-msg-${eventName}`,
				subscriptions.get(subscriber)!, // TS ! we know for sure its defined, because otherwise we wouldnt have gotten here
			);
			subscriptions.delete(subscriber);
		} catch {
			// if removal failed, there's probably nothing to remove
		}
	},
	onMessage({ data: dataStringified }) {
		let error, parsedData, type;
		try {
			parsedData = JSON.parse(dataStringified);
			({ error, type } = parsedData);
		} catch (err) {
			this.Log.error(err as Error);
			return;
		}

		if (error) {
			if (error === 'auth') {
				delete this.auth;
			}
			if (error === 'timeout') {
				this.errorcount += 1;
			}
			return;
		}

		[
			...(this.subscriptions['*']?.entries() || []),
			...(this.subscriptions[type]?.entries() || []),
		].forEach(([subscriber]) => this.callSubscriber(subscriber, parsedData));
	},
	onClose() {
		delete this.instance;
		clearTimeout(this.restart);
		this.errorcount += 1;
		if (this.auth) {
			this.restart = setTimeout(() => {
				this.initAndAuthorize(this.auth);
			}, this.errorcount * 5000);
		}
	},
	onOpen() {
		if ('Notification' in window && Notification.permission === 'default') {
			Notification.requestPermission();
		}
		this.errorcount = 0;
		this.instance?.send(`auth=${this.auth}`);
	},
	initAndAuthorize(auth, wsURL) {
		this.wsURL = wsURL || this.wsURL;
		clearTimeout(this.restart);
		if (!auth || !this.wsURL) return;
		this.auth = auth;
		if (this.instance) return;
		this.instance = new WebSocket(this.wsURL);
		this.instance.onclose = () => this.onClose();
		this.instance.onmessage = (e) => this.onMessage(e);
		this.instance.onerror = () => {
			this.errorcount += 1;
		};
		this.instance.onopen = () => this.onOpen();
	},

	kill() {
		this.errorcount = 0;
		delete this.auth;
		this.instance?.close();
	},
};

declare global {
	interface HTMLElementEventMap {
		'websocket-msg-*': ReturnType<Socket['event']>;
		'websocket-msg-call': ReturnType<Socket['event']>;
		'websocket-msg-callstate': ReturnType<Socket['event']>;
		'websocket-msg-callqueue': ReturnType<Socket['event']>;
		'websocket-msg-push': ReturnType<Socket['event']>;
	}
}
