import { talentRouteDefaultConfig, type TalentRoute } from '../../router';
import { socket } from '@modules/Socket';

const hooks = {
	before: talentRouteDefaultConfig.hooks?.before,
	after: () => {
		socket.kill();
	},
};

export default <Record<string, TalentRoute>>{
	'/assistant/:companyUserId/:moduleId': {
		...talentRouteDefaultConfig,
		as: 'assistant',
		hasHeader: false,
		hasAsideNavigation: false,
		hooks,
		module: () => import('./'),
	},
	'/assistant/:companyUserId': {
		...talentRouteDefaultConfig,
		as: 'assistant',
		hasHeader: false,
		hasAsideNavigation: false,
		hooks,
		module: () => import('./'),
	},
	'/assistant': {
		...talentRouteDefaultConfig,
		as: 'assistant',
		hasHeader: false,
		hasAsideNavigation: false,
		hooks,
		module: () => import('./'),
	},
};
