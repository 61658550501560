import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/identtsuccess': {
		...talentRouteDefaultConfig,
		as: 'identtsuccess',
		uses: (match, ymdRoute, { navigate }) => {
			const { queryString } = match;
			navigate(`/onboarding/documents?identt=success&${queryString}`);
		},
	},
};
