import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/': {
		...talentRouteDefaultConfig,
		as: 'home',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
