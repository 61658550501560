import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/signdoc/:doc/:userId/:moduleId': {
		...talentRouteDefaultConfig,
		as: 'signdoc',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
