/* eslint-disable no-undefined */
// https://openapi-ts.pages.dev/openapi-fetch/middleware-auth
import type { Middleware } from 'openapi-fetch';

export const addAuthorizationErrorHandling = (
	onUnauthorized: () => void,
): Middleware => ({
	async onResponse({ response }) {
		const { status } = response;
		if (!response.headers.get('content-type')?.includes('application/json')) {
			return undefined;
		}
		const { error } = await response.clone().json(); // https://openapi-ts.pages.dev/openapi-fetch/middleware-auth#handling-statefulness
		if (
			error === 'areanotallowed' || // APIv1
			error === 'notallowed' || // APIv1
			status === 403 || // APIv2
			error?.code === 'ACCESS_DENIED_ERROR' // APIv2
		) {
			onUnauthorized?.();
		}

		return undefined;
	},
});
