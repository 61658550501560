import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/mailbox/:conversationId': {
		...talentRouteDefaultConfig,
		as: 'mailbox',
		needsOnboarding: false,
		hasPageTracking: true,
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/mailbox': {
		...talentRouteDefaultConfig,
		as: 'mailbox',
		needsOnboarding: false,
		hasPageTracking: true,
		hasRocketchat: true,
		module: () => import('./'),
	},
};
